import ApiAuth from '../utils/api.js';
import { helperAuth } from '../utils/helper.js';
import * as actions from './actions.types.js';
import * as mutations from './mutations.types.js';

export default function (url, isApp = false) {
  const apiAuth = new ApiAuth(url, isApp);
  return {
    [actions.LOGIN]: ({ commit }, params) => {
      commit(mutations.LOGIN_START);
      return apiAuth.login({ ...params, fingerprint: helperAuth.fingerprint() })
        .then((response) => {
          const { data } = response;
          if (response.status === 401 || response.status === 400) commit(mutations.LOGIN_ERROR, data.error.message);
          if (data.token) {
            commit(mutations.TOKEN_SUCCESS, data.token);
            commit(mutations.LOGIN_SUCCESS, helperAuth.decodeToken(data.token));
            if (isApp) commit(mutations.SET_NATIVE_REFRESH_TOKEN, data.refresh);
          }
          if (data.contracts) commit(mutations.LOGIN_CONTRACTS, data.contracts);
          return Promise.resolve(response);
        })
        .catch((error => {
          commit(mutations.LOGIN_ERROR, error.data.error.message);
        }));
    },

    [actions.REFRESH]: ({ commit }) => {
      commit(mutations.REFRESH_START);
      return apiAuth.refresh({ fingerprint: helperAuth.fingerprint() })
        .then(response => {
          const { data } = response;
          if (response.status === 401 || response.status === 400) {
            commit(mutations.TOKEN_CLEAR);
            commit(mutations.REFRESH_ERROR, `${response.status}: ${data.error.message}`);
          }
          if (data.token) {
            commit(mutations.TOKEN_SUCCESS, data.token);
            commit(mutations.REFRESH_SUCCESS, helperAuth.decodeToken(data.token));
            if (isApp) commit(mutations.SET_NATIVE_REFRESH_TOKEN, data.refresh);
          }
          return Promise.resolve(data.token);
        })
        .catch(error => {
          if (error.status === 401) commit(mutations.TOKEN_CLEAR);
          commit(mutations.REFRESH_ERROR, `${error.status}: ${error.data?.error?.message || error.data?.error || error.data || error }`);
        });
    },

    [actions.SWITCH_CONTRACT]: ({ commit }, {contractId}) => {
      commit(mutations.REFRESH_START);
      return apiAuth.switchContract({ fingerprint: helperAuth.fingerprint(), contractId })
        .then(response => {
          const { data } = response;
          if (response.status === 401) commit(mutations.REFRESH_ERROR, data.error.message);
          if (data.token) {
            commit(mutations.TOKEN_SUCCESS, data.token);
            commit(mutations.REFRESH_SUCCESS, helperAuth.decodeToken(data.token));
            if (isApp) commit(mutations.SET_NATIVE_REFRESH_TOKEN, data.refresh);
          }
          return Promise.resolve(data.token);
        })
        .catch(error => {
          commit(mutations.TOKEN_CLEAR);
          commit(mutations.REFRESH_ERROR, error);
        });
    },

    [actions.GET_CONTRACTS]: ({ commit }) => {
      commit(mutations.CONTRACTS_START);
      return apiAuth.getContracts({ fingerprint: helperAuth.fingerprint() })
        .then(response => {
          const { data } = response;
          if (response.status === 401) commit(mutations.CONTRACTS_ERROR, data.error.message);
          else commit(mutations.CONTRACTS_SUCCESS);
          return Promise.resolve(data);
        })
        .catch(error => {
          commit(mutations.CONTRACTS_ERROR, error);
        });
    },

    [actions.LOGOUT]: ({ commit }) => {
      commit(mutations.LOGOUT_START);
      return apiAuth.logout({ fingerprint: helperAuth.fingerprint() })
        .then(() => {
          commit(mutations.TOKEN_CLEAR);
          commit(mutations.LOGOUT_SUCCESS);
          if (isApp) commit(mutations.CLEAR_NATIVE_REFRESH_TOKEN);
        })
        .catch(error => {
          commit(mutations.LOGOUT_ERROR, error);
        });
    },
  }
}
