import Vuex from 'vuex';
import { helperAuth } from './utils/helper.js';
import AuthInterceptor from './utils/interceptor.js';
import createStore from './store';

export default {
  install (Vue, options) {
    if (!options || !options.store) {
      throw new Error('Please initialise plugin with a Vuex store.')
    }

    const url = options.api || process.env.JWT_API;
    const isApp = !!options.app;

    options.store.registerModule('user', createStore(url, isApp))

    Vue.prototype.$jwt = helperAuth;

    Vue.use(Vuex);
    const store = new Vuex.Store(createStore(url, isApp));
    Vue.prototype.$interceptor = new AuthInterceptor(store);
  }
}