import Vue from 'vue';
import axios from 'axios';

async function buildRequest(data, withRefresh, isApp) {
  if (!isApp) return data;
  const token = withRefresh ? await Vue.prototype.$getNativeRefreshToken() : null;
  const fingerprint = await Vue.prototype.$getAppFingerprint();
  const domain = window ? window.location.host : process.env.DOMAIN;
  return {
    ...data,
    ...(token && {token}),
    fingerprint,
    domain,
  };
}

export default class ApiAuth {
  constructor(url, isApp = false) {
    this.request = axios.create({
      baseURL: url,
      timeout: 25000,
      validateStatus() {
        return true;
      },
      withCredentials: true,
    });

    this.redirectUrl = '/';

    this.options = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    this.isApp = isApp;
  }

  async login(data, _callback) {
    const result = `${data.email}:${data.password}`;
    delete data.email;
    delete data.password;
    const options = JSON.parse(JSON.stringify(this.options));
    options.headers['Authorization'] = `Basic ${btoa(result)}`;

    const req = await buildRequest(data, false, this.isApp);
    return this.request.post('/login', req, options).then(_callback);
  }

  async refresh(data, _callback) {
    const req = await buildRequest(data, true, this.isApp);
    return this.request.post('/refresh', req, this.options).then(_callback);
  }

  async switchContract(data, _callback) {
    const req = await buildRequest(data, true, this.isApp);
    return this.request.post('/switchContract', req, this.options).then(_callback);
  }

  async getContracts(data, _callback) {
    const req = await buildRequest(data, true, this.isApp);
    return this.request.post('/contracts', req, this.options).then(_callback);
  }

  async logout(data, _callback) {
    const req = await buildRequest(data, true, this.isApp);
    return this.request.post('/logout', req, this.options).then(_callback);
  }
}
