import createActions from './actions';
import mutations from './mutations';
import getters from './getters';
import state from './state';

export default function createStore(url, isApp) {
    const actions = createActions(url, isApp);
    return {
        state,
        actions,
        mutations,
        getters,
    }
}
