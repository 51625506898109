import jwtDecode from 'jwt-decode';

class AuthHelper {
  constructor () {
    this.ClientJS = function() {};

    if (typeof window !== 'undefined' && typeof global.navigator !== 'undefined') {
      this.ClientJS = require('clientjs').ClientJS;
    }
  }
  
  fingerprint () {
    let client = new this.ClientJS();
    let browser = client.getBrowserMajorVersion();
    let os = client.getOS();
    let depth = client.getColorDepth();
    let plugins = client.getPlugins();
    let fonts = client.getFonts();
    let time = client.getTimeZone();
    let language = client.getLanguage();
    let canvas = client.getCanvasPrint(); 

    return String(client.getCustomFingerprint(browser, os, depth, plugins, fonts, time, language, canvas));
  }

  decodeToken(token) {
    const now = new Date();
    const later = new Date(now.getTime() + 600000).getTime();
    return {...jwtDecode(token), expire: later};
  }
}

export const helperAuth = new AuthHelper();